import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Brand } from '@ttstr/api';
import { AppState } from '@ttstr/reducers';
import { receiveProducts, receiveTickets, requestBrands } from '@ttstr/actions';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

import Alert from 'reactstrap/lib/Alert';
import Fade from 'reactstrap/lib/Fade';


import {
  Container,
  ContentrArea,
  ProductDetailHeader,
  useProductDetails,
  LoadingSpinner,
  useIntl,
  Masonry,
  Maki2020,
} from '@ttstr/components';

import BrandMarkup from '@ttstr/components/Seo/BrandMarkup';
import InfoSection from './InfoSection';

interface RouteParams {
  readonly id: string;
}

const BrandView: React.FC = () => {
  const params = useParams<RouteParams>();
  const { t } = useTranslation();
  const { language } = useIntl();

  const { brands, brandsReceived } = useProductDetails();
  const { products, productsLoading, tickets, ticketsLoading } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts, receiveTickets, requestBrands } = useActions(mapDispatchToProps);

  const [brand, setBrand] = React.useState<Brand>();

  React.useEffect(() => {
    receiveProducts({ brand_ids: Number(params.id), type: ['Product', 'Package'] });
    receiveTickets({ brand_ids: Number(params.id) });
    requestBrands();
  }, [language, params.id, receiveProducts, receiveTickets, requestBrands]);

  React.useEffect(() => {
    if (brandsReceived) {
      setBrand(brands[Number(params.id)]);
    } else {
      if (brand) setBrand(null);
    }
  }, [params.id, brandsReceived]);

  if (!brandsReceived) {
    return <LoadingSpinner />;
  }

  return (
    <article className="brand-detail">
      <Helmet>
        <body className="brand-detail-page" />
      </Helmet>
      <BrandMarkup brand={brands[Number(params.id)]} />
      <Container>
        <ContentrArea id="brand-before-listing" />
      </Container>

      {brands && (
        <Fade>
          <ProductDetailHeader
            className="brand-header"
            background={brands[Number(params.id)].hero_image.url}
            image={brands[Number(params.id)].hero_image.url}
          />
          <InfoSection title={brands[Number(params.id)].title} description={brands[Number(params.id)].description} />
        </Fade>
      )}

      <Container>
        <ContentrArea id="brand-before-listing" />
      </Container>

      <Container fluid className="brand-product-wrapper">
        <Container className="">
          <h2 className="text-center py-5">Aktuelle Termine</h2>

          {productsLoading || ticketsLoading ? ( // if loading
            <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
          ) : products.length === 0 && tickets.length === 0 ? ( // if no products
            <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
          ) : (
            // else show products
            <Masonry
              id="product-listing"
              products={products
                .concat(tickets)
                .filter((p) => p.brand_ids.includes(brands[Number(params.id)].id))
                .sort((a, b) => new Intl.Collator(language).compare(a.title, b.title)) // first sort alphabetically (language sensitive)
                .sort((a, b) => b.priority - a.priority)} // then sort the highest feature to top of list
              type="list"
              showCity
              showLocation
            />
          )}
        </Container>
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products } = state.Products;
  const productsLoading = state.Products.loading;
  const { tickets } = state.Tickets;
  const ticketsLoading = state.Tickets.loading;
  return {
    products,
    productsLoading,
    tickets,
    ticketsLoading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
  receiveTickets,
  requestBrands,
};

export default React.memo(BrandView);
