import classNames from 'classnames';

import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';

import { Ticket } from '@ttstr/api/products';
import {
  Container,
  ProductDetailHeader,
  // ProductInfoBar,
  VariantListing,
  NativeSlider,
  ContentrArea,
  Loading,
  ExtraTncInfo,
  TicketMarkup,
} from '@ttstr/components';
import MerchantInfo from '@ttstr/components/ProductDetail/MerchantInfo';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import ProductInfoBar from './ProductInfoBar';

const SeatingPlan = React.lazy(() => import('@ttstr/components/ProductDetail/SeatingPlan'));

interface OwnProps {
  readonly product: Ticket;
}

type Props = Readonly<OwnProps>;

const TicketDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState('best-place');

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const images = React.useMemo(() => [product.hero_image.url, ...product.gallery_image_urls.map((i) => i.original)], [
    product,
  ]);
  return (
    <article className="ticket-detail" itemScope itemType="http://schema.org/Event">
      <Helmet>
        <body className={`ticket-detail-page inverse-navbar id-${product.id}`} />
        <script>
          {`fbq('track', 'ViewContent', {
            'content_category': 'Detail Page',
            'content_name': '${product.title}',
          });`}
        </script>
        <script>
          {`b = document.getElementsByClassName('add-product');
            func1 = function() { fbq('track', 'AddToCart') };
            b[0].addEventListener('click', func1);`}
        </script>
      </Helmet>

      <TicketMarkup ticket={product} dontChangeMetaDescription />

      <ProductDetailHeader
        className="product-header"
        background={product.hero_image.url}
        // image={product.hero_image.url}
      >
        <NativeSlider
          className="mb-0"
          images={images.length ? images : [product.hero_image.url]}
          // thumbs={thumbs.length ? thumbs : [product.hero_image.thumb.url]}
          // thumbContainerClassName="random-rotation scribble-hover-overlay"
          // thumbClassName="random-rotation-object"
        />
      </ProductDetailHeader>

        {product.supertitle && <span className="super-title">{product.supertitle}</span>}
        <h1 className="text-center py-5 mt-0 mb-0 mt-sm-4 mb-sm-4">
          <span className="title" itemProp="name">
            {product.title}
          </span>
          <small className="sub-title">{product.subtitle}</small>
        </h1>
          
        <ProductInfoBar product={product} />

        <div className="product-content">

          <Container className="detail-page">
            {product.has_plan && (
              <Nav tabs className="d-flex justify-content-space-between align-items-stretch mb-4">
                <NavItem className="w-50">
                  <NavLink
                    className={
                      'text-center d-flex flex-column ticket-detail-tab h-100 justify-content-center' +
                      ' ' +
                      classNames({ active: activeTab === 'best-place' })
                    }
                    onClick={() => {
                      toggleTab('best-place');
                    }}
                    color="secondary"
                  >
                    <h2 className="h5">{t('PRODUCT.BEST_PLACE_BOOKING')}</h2>
                    {/* <p className="mb-0 text-small">{t('PRODUCT.BEST_PLACE_BOOKING_DESCRIPTION')}</p> */}
                  </NavLink>
                </NavItem>
                <NavItem className="w-50">
                  <NavLink
                    className={
                      'text-center d-flex flex-column ticket-detail-tab h-100 justify-content-center' +
                      ' ' +
                      classNames({ active: activeTab === 'seatingplan' })
                    }
                    onClick={() => {
                      toggleTab('seatingplan');
                    }}
                    color="secondary"
                  >
                    <h2 className="h5">{t('PRODUCT.SEATINGPLAN_BOOKING')}</h2>
                    {/* <p>{t('PRODUCT.SEATINGPLAN_BOOKING_DESCRIPTION')}</p> */}
                  </NavLink>
                </NavItem>
              </Nav>
            )}
            <TabContent activeTab={activeTab} className="pl-0 pr-0">
              <TabPane tabId="best-place" className={classNames('fade', { show: activeTab === 'best-place' })}>
                <Row>
                  <Col lg={{ size: true, order: 'last' }}>
                    <div className="product-info">
                      <h2 className="product-title text-center">{t('TICKET.CHOOSE')}</h2>
                      {product.allow_bestplace !== null && product.allow_bestplace === false ? (
                        <>
                          <p className="bestplace_not_available-description">
                            {t('PRODUCT.BEST_PLACE_BOOKING_NOT_AVAILABLE')}
                          </p>
                          <Button className="bestplace_not_available-button mb-3" onClick={() => setActiveTab('seatingplan')}>
                            {t('PRODUCT.GOTO_SEATINGPLAN_BOOKING')}
                          </Button>
                        </>
                      ) : (
                        <VariantListing product={product} className="mb-5 bg-white" />
                      )}
                      <small className="text-muted d-block">{t(`PRODUCT.FEE_INFO`)}</small>

                      <ContentrArea id="after-listing" />
                    </div>
                  </Col>
                  <Col lg={{ size: true, order: 'first' }}>
                    {product.description && (
                      <>
                        <h2 className="product-title">{t(`PRODUCT.INFORMATION`)}</h2>
                        <div dangerouslySetInnerHTML={{ __html: product.description }} itemProp="description" />
                      </>
                    )}

                    {product.merchant_id && <MerchantInfo merchantId={product.merchant_id} />}
                    {!!product.extra_tnc_ids.length && <ExtraTncInfo extraTncIds={product.extra_tnc_ids} />}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="seatingplan" className={classNames('fade', { show: activeTab === 'seatingplan' })}>
                <Loading>
                  {activeTab === 'seatingplan' && product.has_plan && (
                    <SeatingPlan
                      product={product}
                      variants={
                        Array.isArray(product.online_variants_attributes)
                          ? product.online_variants_attributes
                          : Object.values(product.online_variants_attributes)
                      }
                    />
                  )}
                </Loading>
              </TabPane>
            </TabContent>
          </Container>
        </div>
    </article>
  );
};

export default React.memo(TicketDetail);
