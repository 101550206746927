import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';

import { ContentrArea } from '@ttstr/components';

const BookingPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article className="">
      <h1 className="text-center py-5">Buche uns</h1>
      <Container>
        <Helmet>
          <title>Buche uns</title>
          <body className={`booking-page`} />
        </Helmet>
        <ContentrArea id="booking_main" />
      </Container>
    </article>
  );
};

export default React.memo(BookingPage);
