/**
 * Displays a title and a description.
 * For use in BrandView, CategoryView, etc
 */

import React from 'react';
import { Helmet } from 'react-helmet';

import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { ContentrArea } from '@ttstr/components';

type Props = {
  title: string;
  subtitle?: string;
  imageUrl?: string;
  description: string;
};

const InfoSection: React.FC<Props> = ({ title, subtitle, imageUrl, description }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {/* TODO: canonical url */}
      </Helmet>
      <h1 className="py-5 text-center mb-5">{title}</h1>
      {/* <h2 className="text-center">{subtitle}</h2> */}

      <Container fluid className="bg-white pb-5">
        {/* <Row>
          {image.productthumb && (
            <Col>
              <img src={image.productthumb.url} alt="" className="image" />
            </Col>
          )}
          <Col>
            <h1 className="title">{title}</h1>
            {description && (
              <section dangerouslySetInnerHTML={{ __html: description }} itemProp="description" />
            )}
          </Col>
        </Row> */}
        {imageUrl && (
          <Col>
            <img src={imageUrl} alt="" className="image" />
          </Col>
        )}
        <Container className='pt-5'>
          <div className="row">
            <div className="col-md-8">
              {description && <div dangerouslySetInnerHTML={{ __html: description }} itemProp="description" />}
            </div>
            <div className="col-md-4">
              <ContentrArea id="artist-infobox-top" />
              <ContentrArea id="artist-infobox-bottom" />
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default InfoSection;
